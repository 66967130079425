import Swiper from 'swiper';

var details = [{
        name: 'Aerial photography with Sweet Escape',
        position: 'Every great success comes from the perfect combination of the right people, with the right process and the right idea'
    },
    {
        name: 'New technology in CES annual confer',
        position: 'Every great success comes from the perfect combination of the right people, with the right process and the right idea'
    },
    {
        name: 'Aerial photography with Sweet Escape',
        position: 'Every great success comes from the perfect combination of the right people, with the right process and the right idea'
    }
];
var news = new Swiper('.news', {
    slidesPerView: 3,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slidesOffsetAfter: 220
});

var generatedHTML = [];

for (var i = 0; i < 5; i++) {
    var n = i;
    var elem = document.createElement("img");
    if (i > 2)
        n = i - 3
    elem.src = "/images/news" + (n + 1) + ".png";
    var s = i + 1;
    var idName = "news" + s
    var idOverlay = "overlaynews" + s
    var idh1 = "h1news" + s
    var idh3 = "h3news" + s
    var slide = document.createElement("div");
    slide.classList.add("swiper-slide");
    elem.id = idName;
    slide.appendChild(elem);

    var name_tag = document.createElement("H1")
    var name_node = document.createTextNode(details[n].name);
    name_tag.id = idh1
    name_tag.appendChild(name_node);
    slide.appendChild(name_tag);

    var desg_tag = document.createElement("H3")
    var desg_node = document.createTextNode(details[n].position);
    desg_tag.id = idh3
    desg_tag.appendChild(desg_node);
    slide.appendChild(desg_tag);

    var overlay_tag = document.createElement("div")
    overlay_tag.className += "overlaynewspage"
    overlay_tag.id = idOverlay;
    var a_tag = document.createElement("a")
    a_tag.setAttribute('href', "#");
    a_tag.innerText = "READ MORE";
    overlay_tag.appendChild(a_tag);
    slide.appendChild(overlay_tag);

    generatedHTML.push(slide);
}

news.addSlide(1, generatedHTML);

for (var i = 0; i < 5; i++) {
    var n = i + 1
    var idName = "news" + n;
    document.getElementById(idName).addEventListener('mouseover', function (a) {
        var pathname = '/images/' + a.srcElement.id + '.png'
        var overlayIndex = a.srcElement.id.slice(-1);
        var overlayName = 'overlaynews' + overlayIndex
        var h1Name = 'h1news' + overlayIndex
        var h3Name = 'h3news' + overlayIndex

        if (a.srcElement.id === 'news4') {
            pathname = '/images/news1.png'
        }
        if (a.srcElement.id === 'news5') {
            pathname = '/images/news2.png'
        }
        if (a.srcElement.id === 'news6') {
            pathname = '/images/news3.png'
        }
        document.getElementById('news-page').style.backgroundImage = "url(" + pathname + ")";
        document.getElementById(overlayName).classList.add('overlay-come-now')
        document.getElementById(h1Name).classList.add('h1-come-now')
        document.getElementById(h3Name).classList.add('h3-come-now')
    })
    document.getElementById(idName).addEventListener('mouseleave', function (a) {
        var pathname = '/images/' + 'newsbackground' + '.png'
        var overlayIndex = a.srcElement.id.slice(-1);
        var overlayName = 'overlaynews' + overlayIndex
        var h1Name = 'h1news' + overlayIndex
        var h3Name = 'h3news' + overlayIndex
        document.getElementById(overlayName).classList.remove('overlay-come-now')
        document.getElementById(h1Name).classList.remove('h1-come-now')
        document.getElementById(h3Name).classList.remove('h3-come-now')
        document.getElementById('news-page').style.backgroundImage = "url(" + pathname + ")";
    })
}